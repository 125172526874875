@font-face {
  font-family: "Calibre";
  src: url("/static/fonts/calibre/calibre-web-semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("/static/fonts/calibre/calibre-web-medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("/static/fonts/calibre/calibre-web-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}